import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AnchorLinks mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">Code of Conduct</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Contributor License Agreement</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Support</AnchorLink>
    </AnchorLinks>
    <h2 {...{
      "id": "code-of-conduct"
    }}>{`Code of Conduct`}</h2>
    <h3 {...{
      "id": "the-core-teams-promise"
    }}>{`The core team’s promise`}</h3>
    <p>{`Carbon is funded and built by IBM. Although that means we build for the company’s business needs, it also means we have a brilliant team of engineers and designers working full time to make Carbon better. As one of the world’s largest companies, IBM has thousands of people across the globe using and contributing to Carbon.`}</p>
    <p>{`We use what we build, and we’ve made it open source for anyone to use and contribute back to.`}</p>
    <h3 {...{
      "id": "who-is-the-code-of-conduct-for"
    }}>{`Who is the Code of Conduct for?`}</h3>
    <p>{`The Carbon Design System is built for everybody and if you use or contribute to Carbon, you are part of this community and we expect you to adhere to our community standards.`}</p>
    <h3 {...{
      "id": "feedback-and-critique"
    }}>{`Feedback and critique`}</h3>
    <p>{`Building great products isn’t easy, and there’s usually more than one right answer. If you’re taking part in any discussion, whether on Slack, Twitter, Gitter, GitHub, or even in person, please respect one another. Keep the mission in mind, and help us make Carbon better.`}</p>
    <h3 {...{
      "id": "be-understanding"
    }}>{`Be understanding`}</h3>
    <p>{`Differing needs and differing perspectives make Carbon comprehensive. Be welcoming, be understanding, and be kind. If one user expresses frustrations or complications, there are likely dozens of others who feel the same way. Encourage community members to speak up.`}</p>
    <h2 {...{
      "id": "contributor-license-agreement"
    }}>{`Contributor License Agreement`}</h2>
    <p>{`The Carbon core team works for IBM. To accept contributions, we need a signed Contributor License Agreement (CLA) from you before code contributions can be reviewed and merged. If you have questions, please don’t hesitate to `}<a parentName="p" {...{
        "href": "https://www.carbondesignsystem.com/help/support#email"
      }}>{`reach out`}</a>{`.`}</p>
    <h2 {...{
      "id": "support"
    }}>{`Support`}</h2>
    <p>{`Have questions? Found a bug? Learn where to go and what to do by visiting the `}<a parentName="p" {...{
        "href": "/help/support"
      }}>{`Support page`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      